import React from 'react'

export const About = () => {
  return (
    <>
     <div>This is about Component</div>
    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium voluptas hic deleniti tempora ullam, quo, autem expedita nam non tenetur at culpa possimus quis quisquam? Deleniti rerum non dignissimos atque impedit fuga nisi eligendi.</p>
    </>
 )
}
